import React, { useEffect, useState } from "react";
import { message } from "antd";
import { RegionDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerOTP, verifyRegisterOtp } from "../../../redux/Slices/authSlice";
import { uploadFile } from "../../../redux/Slices/templateSlice";
import { image } from "../../../app/utils/common/image";
import Input from "../../atoms/Input/Input";
import Loader from "../../atoms/Loader/Loader";
import ErrorText from "../../atoms/ErrorText/ErrorText";
import VerifyLoginOtp from "../../molecules/VerifyLoginOtp/VerifyLoginOtp";
import "./SignUpPage.scss"

export const SignUpPage = () => {

  const [showOtpModal, setShowOtpModal] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [activeButton, setActiveButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [city, setCity] = useState("")
  const [state, setState] = useState('')
  const [address, setAddress] = useState('')
  const [pincode, setPincode] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [registrationProof, setRegistrationProof] = useState('')
  const [gstCertification, setGstCertification] = useState('')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);

  const otpId = authState.otpId;
  const otpString = otp.join("");

  const registerVerifyData = {
    "role": 4,
    "name": name,
    "phone_no": phoneNumber,
    "country_code": "+91",
    "otp_id": otpId,
    "otp": otpString,
    "address": address,
    "city": city,
    "state": state,
    "pin_code": pincode,
    "registration_proof": registrationProof,
    "gst_no": gstNo,
    "gst_certificate": gstCertification
  }

  useEffect(() => {
    checkFieldsFilled();
  }, [name, phoneNumber, city, state, pincode, gstNo, registrationProof, gstCertification]);

  // Handle to register verify otp
  const handleVerifyRegisterOtp = () => {
    setLoading(true);
    setErrorMessage("");
    dispatch(
      verifyRegisterOtp({ registerVerifyData })) // Dispatch the register verify OTP action
      .then((action) => {
        setLoading(false);
        if (action.type === "auth/verifyRegisterOtp/fulfilled") {
          const { data } = action.payload;
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          message.success('You have successfully register.Please login')
          navigate("/login");
        } else {
          setErrorMessage(
            action.payload.error.message || "Failed to verify OTP"
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage("Error verifying OTP: " + err.message);
      });
  };

  // Handle Input change for details screen
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage('')
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'pincode':
        setPincode(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'gstno':
        setGstNo(value);
        break;
      default:
        break;
    }
    // setActiveButton(value !== "");
    checkFieldsFilled();
    setErrorMessage("");
  };

  const handlePhoneChange = (event) => {
    if (event.target.value.length <= 10) {
      setPhoneNumber(event.target.value);
      // setActiveButton(event.target.value.length === 10);
      checkFieldsFilled();
      setErrorMessage("");
    }
  };

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    setErrorMessage("");
  };

  const handleSendOtp = () => {
    setLoading(true);
    setErrorMessage("");
    const registerData =
    {
      "role": 4,
      "phone_no": phoneNumber,
      "country_code": "+91",
      "portal": "web_agency",
    }

    dispatch(registerOTP({ registerData })).then((action) => {
      // Dispatch the send OTP action
      setLoading(false);
      if (action.type === "auth/registerOTP/fulfilled") {
        setShowOtpModal(true);
        setErrorMessage("");
      } else if (action.type === "auth/registerOTP/rejected") {
        setErrorMessage(action.payload.error.message);
      }
    });
  };

  const handleLogin = () => {
    navigate("/login")
  }

  // Handle image upload
  const handleUploadImg = async (e, bucket, fromRegister = false) => {
    const selectedImage = e.target.files[0];

    try {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("bucket", bucket);

      // Dispatch the upload action
      const uploadResponse = await dispatch(uploadFile({ formData, fromRegister: true })).unwrap();
      const url = uploadResponse?.data?.imageUrl;

      if (bucket === "registrationProofs") {
        setRegistrationProof(url);
      } else if (bucket === "gstCertificates") {
        setGstCertification(url);
      }
      checkFieldsFilled();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Check if all fields are filled
  const checkFieldsFilled = () => {
    if (
      name &&
      phoneNumber.length === 10 &&
      city &&
      state &&
      pincode &&
      gstNo &&
      registrationProof &&
      gstCertification
    ) {
      setActiveButton(true);  // Enable the button
    } else {
      setActiveButton(false); // Disable the button
    }
  };
  return <div>
    <div className="login-form">
      <img src={image.LogoHere} alt="" className="login-image" />
      <div className="login-page">
        <div className="login-page-container">
          <div className="login-page-cover-container">
            <div className="login-page-wrapper">
              <div className="d-flex justify-content-center w-full">
                <div className="visueats-logo-container">
                  <div className="aside-logo">
                    <div className="logo-text">AdSimpl</div>
                  </div>
                  <div className="admin-panel">
                    <span>Dashboard</span>
                  </div>
                </div>
              </div>
              <div className="login-container">
                <div>{!showOtpModal ? 'Sign Up' : 'Verification Code'}</div>
                <p className="login-text">
                  {!showOtpModal
                    ? 'Register to continue to the Agency.'
                    : 'Please enter the OTP received via SMS on the mobile number you have provided'}
                </p>
              </div>

              {!loading ? (
                !showOtpModal && (
                  <>
                    <div className="email-container">
                      <div className="flex gap-3 flex-col">
                        <Input label="Name *" placeholder="Enter your Name" type="text" name="name" value={name} onChange={handleInputChange} />
                        <Input
                          label="Address"
                          placeholder="Enter Address"
                          type="text"
                          name="address"
                          value={address}
                          onChange={handleInputChange}
                        />
                        <Input label="City *" placeholder="Enter City name" type="text" name="city" value={city} onChange={handleInputChange} />
                        <div>
                          <div className="form-label">State *</div>
                          <RegionDropdown
                            classes="countryDropdown"
                            name="state"
                            defaultOptionLabel="Select State"
                            country="India"
                            value={state}
                            onChange={(val) => handleInputChange({ target: { value: val, name: 'state' } })}
                          />
                        </div>
                        <Input label="Pincode *" name="pincode" placeholder="Enter Pincode" value={pincode} onChange={handleInputChange} />
                        <Input
                          label="Phone Number *"
                          placeholder="Enter your phone number"
                          type="tel"
                          name="phonenumber"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                        />
                        <Input label="Registration proof *" type="file" onChange={(e) => handleUploadImg(e, 'registrationProofs')} />
                        <Input
                          label="GST Number *"
                          placeholder="Enter GST Number"
                          type="text"
                          name="gstno"
                          value={gstNo}
                          onChange={handleInputChange}
                        />
                        <Input label="GST certificate *" type="file" onChange={(e) => handleUploadImg(e, 'gstCertificates')} />
                        {errorMessage && <ErrorText message={errorMessage} show />}
                      </div>
                    </div>
                    <div className="flex flex-col pt-[4%] border-t w-[100%] gap-2">
                      <button
                        className={`flex items-center justify-center py-[8px] bg-[#10b981] text-white border-[#1d892b] text-sm w-full rounded-lg ${activeButton ? '' : 'opacity-50 cursor-not-allowed'
                          }`}
                        disabled={!activeButton}
                        onClick={handleSendOtp}
                      >
                        <div className="flex items-center">
                          <div>Register</div>
                        </div>
                      </button>
                      <div className="register-now">
                        <p>
                          Already have an account? <span onClick={handleLogin}>Login</span>
                        </p>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div className="flex items-center justify-center">
                  <Loader height="h-[190px]" paddingRight="pr-[3rem]" />
                </div>
              )}

              {showOtpModal && (
                <VerifyLoginOtp
                  showOtpModal={showOtpModal}
                  handleOtpChange={handleOtpChange}
                  otp={otp}
                  handleVerifyOtp={handleVerifyRegisterOtp}
                  setActiveButton={setActiveButton}
                  otpId={otpId}
                  setOtp={setOtp}
                  errorMessage={errorMessage}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};
