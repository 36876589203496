import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRazorpay from "react-razorpay";
import { Modal, message } from 'antd';
import { image } from '../../../../app/utils/common/image';
import { dateAvailable, getCategories, postAds, screenAvailable, selectTemplateStatus, uploadFile } from '../../../../redux/Slices/templateSlice';
import AddYoursImage from '../AddYourImage/AddYoursImage';
import AddYoursAgencyDetails from '../AddYoursAgencyDetails/AddYoursAgencyDetails';
import AddYoursScreenDetails from '../AddYoursScreenDetails/AddYoursScreenDetails';
import Loader from '../../../atoms/Loader/Loader';
import { getProfileData } from '../../../../redux/Slices/agencyProfileSlice';
import { groupDates } from './adYours';
import Input from '../../../atoms/Input/Input';
import './AddYoursModalTemp.scss';
import { fetchMyAds, selectMyAdsData } from '../../../../redux/Slices/myAdsSlice';
import moment from 'moment';



const AddYoursModalTemp = ({ setModalIsOpen, modalIsOpen }) => {
    const userData = useSelector(getProfileData);

    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState(userData?.name || '')
    const [userPhoneNo, setUserPhoneNo] = useState(userData?.phone_no || '')
    const [shopName, setShopName] = useState(userData?.shop_name || '')
    const [imageUrl, setImageUrl] = useState('');
    const [screen, setScreen] = useState(1);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('India');
    const [pincode, setPincode] = useState('');
    const [profileLogo, setProfileLogo] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [noOfScreen, setNoOfScreen] = useState('');
    const [days, setDays] = useState('');
    const [startDate, setStartDate] = useState('');
    const [screenData, setScreenData] = useState(null);
    const [dateData, setDateData] = useState(null)
    const [postData, setPostData] = useState(null)
    const [fieldErrors, setFieldErrors] = useState({});
    const [discountAmt, setDiscountAmt] = useState('');
    // const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    const profileData = useSelector(getProfileData);
    const myAds = useSelector(selectMyAdsData);

    const ProfileId = profileData.id;
    const paginationData = myAds.pagination;

    const templateStatus = useSelector(selectTemplateStatus);

    const categories = useSelector((state) => state.template.data);
    const { OrderDetails } = useSelector((state) => state.template);

    useEffect(() => {
        if (templateStatus === 'loading') {
            setLoading(true);
        } else if (templateStatus === 'succeeded' || templateStatus === 'failed') {
            setLoading(false);
        }
    }, [templateStatus]);

    useEffect(() => {
        if (!categories?.categories?.length) {
            dispatch(getCategories());
        }
    }, [dispatch, categories]);

    // To get categories Option
    const categoriesOptions = categories?.categories?.map((item) => ({ id: item.id, name: item.name }));

    const handlePreviousBtn = () => {
        if (screen > 1) {
            setScreen(screen - 1);
        }
    };

    const handleNextBtn = () => {
        if (screen === 1) {
            setScreen(2);
        } else if (screen === 2) {
            handleScreenAvailable();
        } else if (screen === 3) {
            handleDateAvailable();
        } else if (screen === 4) {
            handleSubmitAds();
        }
    };

    const handleUploadImg = async (file, bucket) => {
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bucket', bucket);

            const uploadResponse = await dispatch(uploadFile({ formData, fromRegister: false })).unwrap();
            const url = uploadResponse?.data?.imageUrl;
            setImageUrl(url);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleUploadProfileImg = async (file, bucket) => {
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bucket', bucket);

            const uploadResponse = await dispatch(uploadFile({ formData, fromRegister: false })).unwrap();
            const url = uploadResponse?.data?.imageUrl;
            setProfileLogo(url)
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldErrors('')
        switch (name) {
            case 'city':
                setCity(value);
                break;
            case 'state':
                setState(value);
                break;
            case 'country':
                setCountry(value);
                break;
            case 'noOfScreen':
                setNoOfScreen(value);
                break;
            case 'startDate':
                setStartDate(value);
                break;
            case 'pincode':
                setPincode(value);
                break;
            case 'userName':
                setUserName(value);
                break;
            case 'userPhoneNo':
                setUserPhoneNo(value);
                break;
            case 'shopName':
                setShopName(value);
                break;
            case 'discountAmt':
                setDiscountAmt(value);
                break;
            default:
                break;
        }
    };
    const handleSelectInput = (selectedOption) => {
        const value = selectedOption.value;
        setDays(value);
    };

    const handleScreenAvailable = () => {
        setLoading(true);
        const category_ids = selectedCategories.map(category => category.id);

        dispatch(screenAvailable({
            category_ids,
            city,
            state,
            pin_code: pincode
        }))
            .then((response) => {
                if (response?.payload?.success) {
                    setScreenData(response.payload.data);
                    setScreen(3);
                    setLoading(false);
                } else {
                    const errors = response?.payload?.error || {};
                    setFieldErrors(errors);
                }
            })
            .catch((error) => {
                console.error("Error fetching screen availability:", error);
            });
        setLoading(false);
    };

    const handleDateAvailable = () => {
        setLoading(true);
        const category_ids = selectedCategories.map(category => category.id);
        dispatch(dateAvailable({
            category_ids,
            display_quantity: parseInt(noOfScreen),
            date_interval: parseInt(days),
            start_date: startDate,
            city: city,
            state: state,
            pin_code: pincode
        })).then((response) => {
            if (response?.payload?.success) {
                const dateData = response.payload.data;
                setDateData(dateData);
                setScreen(4);
                setLoading(false);
            } else {
                const errors = response?.payload?.error || {};
                setFieldErrors(errors);
            }
        })
            .catch((error) => {
                console.error("Error fetching screen availability:", error);
            });
        setLoading(false);
    };
    const handleSubmitAds = () => {
        setLoading(true);
        const category_ids = selectedCategories.map(category => category.id);
        const postData = {
            ad_details: {
                template_id: 1,
                selected_city: city,
                selected_state: state,
                selected_pin_code: pincode,
                user_id: userData?.id,
                start_date: startDate,
                end_date: moment(startDate).add(days, 'day').format("YYYY-MM-DD"),
                start_time: "00:00:00",
                end_time: "23:59:59",
                date_interval: parseInt(days),
                category_ids: category_ids,
                image: imageUrl,
                country_code: "+91",
                phone_no: userPhoneNo || userData?.phone_no,
                logo: profileLogo || userData?.profile_logo,
                shop_name: shopName || userData?.shop_name,
                sub_cost: Number(totalAmount),
                cost: Number(totalAmount)
            },
            additional_ads_details: groupByDates,
        }
        dispatch(postAds(postData)).then((response) => {
            if (response?.payload?.success) {
                handlePayClick(response?.payload?.data?.order_data);

                // setPostData(response.payload.data)
                // setScreen(5);
                // setLoading(false);
            } else {
                const errors = response?.payload?.error || {};
                setFieldErrors(errors);
            }
        })
            .catch((error) => {
                console.error("Error fetching screen availability:", error);
            });
        setLoading(false);
    }


    //razorpay 
    const [Razorpay] = useRazorpay();

    const handlePayClick = useCallback((data) => {
        const options = {
            key: "rzp_test_U2ed1PbYYkXvmm",
            amount: data.amount,
            currency: data.currency,
            name: "Mihir Patil",
            description: "Testing razorpay",
            //   image: "https://example.com/your_logo",
            order_id: data.id,
            handler: (res) => {
                if (res) {
                    setModalIsOpen(false)
                    message.success('Payment has been done successfully!');
                    dispatch(fetchMyAds({ user_id: ProfileId, page: myAds.pagination.currentPage, limit: 8 }));
                }
            },
            prefill: {
                name: "The Coding Studio",
                email: "mihirdhore.tcs@gmail.com",
                contact: "9999999999",
            },
            notes: {
                address: "The Coding Studio, Surat, Gujarat",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [OrderDetails]);

    // Calculation of group dates
    const groupByDates = groupDates(
        dateData?.data ?? [],
        parseInt(noOfScreen),
    );

    const findOutDiffOfDaysWithFreq = groupByDates
        .map(item => {
            const startDate = new Date(item.start_date);
            const endDate = new Date(item.end_date);
            const timeDifference = endDate - startDate;
            return timeDifference / (1000 * 60 * 60 * 24) + 1;
        })
        .reduce((total, it) => (total += it), 0);
    // const totalAmount =
    //     findOutDiffOfDaysWithFreq *
    //     parseInt(noOfScreen) *
    //     dateData?.price;

    const totalAmount = parseInt(noOfScreen)
    return (
        <Modal
            open={modalIsOpen}
            onCancel={() => setModalIsOpen(false)}
            footer={null}>
            <div className="save-container-ads">
                <div className="save-header">
                    <div className='text-[--themeColor] text-[18px] font-bold ml-[35%]'>Add Your details</div>
                    <button className="btn-modal close-button" aria-label="Close" onClick={() => setModalIsOpen(false)}>
                        <img src={image.IMG_CLOSE} className="image_close" />
                    </button>
                </div>
                <div className='save-main-container'>
                    {!loading ? <div>
                        <div className='h-[70vh] overflow-auto pr-[12px] pb-[15px]'>
                            {screen === 1 && <AddYoursImage handleUploadImg={handleUploadImg} imageUrl={imageUrl} setImageUrl={setImageUrl} />}

                            {screen === 2 && <AddYoursAgencyDetails
                                city={city}
                                state={state}
                                country={country}
                                pincode={pincode}
                                handleInputChange={handleInputChange}
                                categoriesOptions={categoriesOptions}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                handleScreenAvailable={handleScreenAvailable}
                                screenData={screenData}
                                userData={userData}
                                fieldErrors={fieldErrors}
                                userName={userName}
                                userPhoneNo={userPhoneNo}
                                shopName={shopName}
                                profileLogo={profileLogo}
                                setProfileLogo={setProfileLogo}
                                handleUploadProfileImg={handleUploadProfileImg}
                            />}

                            {screen === 3 && <AddYoursScreenDetails
                                handleInputChange={handleInputChange}
                                noOfScreen={noOfScreen}
                                days={days}
                                handleSelectInput={handleSelectInput}
                                screenData={screenData}
                                handleDateAvailable={handleDateAvailable}
                                startDate={startDate}
                                fieldErrors={fieldErrors}
                                discountAmt={discountAmt}
                                totalAmount={totalAmount}
                            />}
                            {screen === 4 && <div>
                                <div className='payDetails'>
                                    <p><strong>Shop Name:</strong> {shopName || userData?.shop_name}</p>
                                    <p><strong>Mobile Number:</strong> {userPhoneNo || userData?.phone_no}</p>
                                    <p><strong>Total Amount:</strong> {totalAmount}</p>
                                </div>
                            </div>}

                            {screen === 5 && postData &&
                                <div>
                                    <div className='payDetails'>
                                        <p><strong>Shop Name:</strong> {shopName || userData?.shop_name}</p>
                                        <p><strong>Mobile Number:</strong> {userPhoneNo || userData?.phone_no}</p>
                                        <p><strong>Total Amount:</strong> {totalAmount}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="denied-btns">
                            {screen >= 2 && <button className="denied-btn" onClick={handlePreviousBtn}>Previous</button>}
                            {screen !== 4 && screen !== 5 && <button className="denied-changes" onClick={handleNextBtn}>Next</button>}
                            {screen === 4 && screen !== 5 && <button className="denied-changes" onClick={handleSubmitAds}>Pay</button>}
                            {/* }   {screen === 5 && <button className="denied-changes" onClick={handlePayClick}>Pay</button>} */}
                        </div>
                    </div> : <div className='max-h-[450px] flex items-center justify-center h-[450px]' >
                        <Loader paddingRight={'pr-[14%]'} />
                    </div>}
                </div>
            </div>
        </Modal>
    );
};

export default AddYoursModalTemp;