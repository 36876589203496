import React from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { RegionDropdown } from 'react-country-region-selector';
import Input from '../../../atoms/Input/Input'
import ErrorText from '../../../atoms/ErrorText/ErrorText';
import './AddYoursAgencyDetails.scss'
import ReactImageUploading from 'react-images-uploading';
import { image } from '../../../../app/utils/common/image';
import { FiEdit } from 'react-icons/fi';

const AddYoursAgencyDetails = (props) => {
    const { selectedCategories, setSelectedCategories, categoriesOptions, city, handleInputChange, state, fieldErrors, pincode, userData, userPhoneNo, userName, shopName, profileLogo, handleUploadProfileImg } = props

    // Handle the image selection and update the URL
    const handleChange = (imageList) => {
        if (imageList.length > 0) {
            const selectedImage = imageList[0].file;
            handleUploadProfileImg(selectedImage, 'userDetails');
        } else {
            console.error('No image selected for upload');
        }
    };
console.log('shopName :>> ', shopName);
    return (
        <div className='flex flex-col gap-4 mt-[20px]'>
            <div>
                <div className='form-label'>Select Category *</div>
                <MultiSelect
                    value={selectedCategories}
                    onChange={(e) => setSelectedCategories(e?.value)}
                    options={categoriesOptions}
                    display="chip"
                    optionLabel="name"
                    placeholder="Select Category"
                    className="multiselect"
                    required
                />
                {fieldErrors.message === '"category_ids" must contain at least 1 items' && <ErrorText show message="This is required field" />}
            </div>
            <div>
                <Input
                    label="Name *"
                    placeholder="Enter name"
                    type="text"
                    id="userName"
                    name="userName"
                    value={userName}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"name" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>
            <div>
                <Input
                    label="Shop Name *"
                    placeholder="Enter shop name"
                    type="text"
                    id="shopName"
                    name="shopName"
                    value={shopName}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"shop_name" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>

            <div className="label-edit-container">
                <div className='label-edit'>
                    <div className='form-label'>Shop Logo *</div>
                    {/* <FiEdit size={20} color='var(--themeColor)' cursor={'pointer'} onClick={handleChange} /> */}
                </div>
                <ReactImageUploading
                    multiple={false}
                    value={[]}
                    onChange={handleChange}
                    allowNonImageType={true}
                    maxFileSize={3000000}
                    acceptType={['jpeg', 'jpg', 'png', 'svg']}
                    label='shop logo'
                >
                    {({ onImageUpload }) => (
                        <div onClick={onImageUpload}>
                            <button className='upload-image-button' style={{ paddingTop: "2px", width: "175px", height: "175px", borderRadius: '50%', margin: '0px' }}>
                                {profileLogo || userData?.profile_logo ? (
                                    <img src={profileLogo || userData?.profile_logo} alt="Selected" className="image-preview h-[175px] w-[175px] rounded-[50%]" />
                                ) : (
                                    <>
                                        <img src={image.IMG_UPLOAD} className="image_gallery_icon" alt='' />
                                        <span className="upload-image-text">Upload File</span>
                                        <span className="upload-image_subtext">Type: JPEG, JPG, PNG<br /> Size: Images Max 3MB</span>
                                    </>
                                )}
                            </button>
                        </div>
                    )}
                </ReactImageUploading>
            </div>


            <div>
                <Input
                    label="City *"
                    placeholder="Enter City name"
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"city" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>

            <div>
                <div className='form-label'>State *</div>
                <RegionDropdown
                    classes='countryDropdown'
                    name="state"
                    defaultOptionLabel='Select State'
                    country="India"
                    value={state}
                    onChange={(val) => { handleInputChange({ target: { value: val, name: "state" } }) }}
                />
                {fieldErrors.message === '"state" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>
            <div>
                <Input
                    label="Pincode *"
                    placeholder="Enter Pincode"
                    type="number"
                    name="pincode"
                    value={pincode}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"pin_code" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>
            <div>
                <Input
                    label="Phone Number *"
                    placeholder="Enter Phone number"
                    type="tel"
                    id="userPhoneNo"
                    name="userPhoneNo"
                    value={userPhoneNo}
                    onChange={handleInputChange}
                />
                {fieldErrors.message === '"phone_no" is not allowed to be empty' && <ErrorText show message="This is required field" />}
            </div>
        </div>
    )
}

export default AddYoursAgencyDetails